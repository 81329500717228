import { ChakraProvider } from '@chakra-ui/react';
import MicrositeConfigProvider, {
    EventAccessTypeEnum,
    RegistrationTypeEnum
} from '@components/Site/provider/MicrositeConfigProvider';
import { analyticsConfig, firebaseAppConfig } from '@config';
import { GetEventQuery } from '@graphql/hasura';
import { AnalyticsProvider } from '@lib/analytics';
import { SiteConfigType } from '@lib/apollo';
import FirebaseAppProvider from '@lib/firebase';
import initializeSentry from '@lib/sentry/initialize';
import { sendPageViewEvent } from '@lib/tag-manager';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { getSiteTheme } from 'theme';

const { publicRuntimeConfig } = getConfig();

initializeSentry();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const App: NextPage<AppProps<{ firebaseConfig?: any; eventData?: GetEventQuery }>> = ({
    Component,
    pageProps
}) => {
    const {
        site,
        eventData,
        ...restPageProps
    }: { site?: SiteConfigType; eventData?: GetEventQuery } = pageProps;
    const router = useRouter();
    const { locale } = router;
    const [localeMessages, setLocaleMessages] = useState();
    const hasuraLocale = locale ? locale.replace('-', '_') : '';

    useEffect(() => {
        const handleRouteChange = (url: string) => {
            sendPageViewEvent(url);

            if (typeof window.CustomEvent === 'function') {
                const path = url.replace(/^\/(de|en|fr|fr-ch|en-gb|de-ch|de-at)\//i, '/');
                const customEvent = new CustomEvent('app_page_view', { detail: { path } });
                document.dispatchEvent(customEvent);
            }
        };
        router.events.on('routeChangeComplete', handleRouteChange);
        router.events.on('hashChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
            router.events.off('hashChangeComplete', handleRouteChange);
        };
    }, []);

    useEffect(() => {
        if (typeof window.CustomEvent === 'function') {
            const path = router.asPath;
            const customEvent = new CustomEvent('app_page_view', { detail: { path } });

            // We send the event with a little delay, to allow the background firebase authentication
            // to initialize.
            setTimeout(() => {
                document.dispatchEvent(customEvent);
            }, 3000);
        }
    }, []);

    useEffect(() => {
        if (site && hasuraLocale) {
            setTimeout(async () => {
                const localeMessages: any = {};

                site.translations.map((item: any) => {
                    if (
                        typeof item[hasuraLocale] !== undefined &&
                        item[hasuraLocale] !== null &&
                        item[hasuraLocale] !== ''
                    ) {
                        localeMessages[item.id] = item[hasuraLocale];
                    } else {
                        localeMessages[item.id] = item.source_string;
                    }
                });
                setLocaleMessages(localeMessages);
            }, 0);
        }
    }, [site, hasuraLocale]);

    const siteTheme = getSiteTheme(site?.ui_theme?.theme || {});

    if (!site) {
        return (
            <ChakraProvider resetCSS theme={siteTheme}>
                <Component {...restPageProps} />
            </ChakraProvider>
        );
    }

    if (site.logo) {
        siteTheme.logo = site.logo;
    }

    const logo_localized: any = site.logo_localized;

    if (logo_localized && logo_localized.default) {
        siteTheme.logo = logo_localized.default;

        if (hasuraLocale && logo_localized[hasuraLocale]) {
            siteTheme.logo = logo_localized[hasuraLocale];
        }
    }

    //TODO: hard-coded solution for an event with a specific logo
    if (eventData?.events_by_pk?.id === '00eac99c-fadf-4e14-91d5-ae0fe0b8c0c6') {
        siteTheme.logo = `https://firebasestorage.googleapis.com/v0/b/ext-ch-lundbeck-ch.appspot.com/o/public%2Fmicrosites%2F2021-05-10_15h59_32.png?alt=media&token=72b9cba0-0e13-4e60-ac1f-1fdfe42f2273`;
    }

    if (eventData?.events_by_pk?.id === '95d087da-9173-4191-bb61-a687bc35d508') {
        siteTheme.logo = `https://firebasestorage.googleapis.com/v0/b/ext-ch-lundbeck-ch.appspot.com/o/public%2Fmicrosites%2FLUPS_Logo_LU-OW-NW_rgb%2BSchutzzone.png?alt=media&token=4bb42d2e-167b-49cc-a179-c6800c404a32`;
    }

    if (eventData?.events_by_pk?.id === 'a9a2b4c8-e5fd-4ea2-802c-c3cb94dd49de') {
        siteTheme.logo = `https://firebasestorage.googleapis.com/v0/b/ext-ch-lundbeck-ch.appspot.com/o/public%2Fmicrosites%2FLogo_Privatklinik%20Meiringen.png?alt=media&token=72538281-a0b1-4238-b66f-08272efbb7dd`;
    }

    const defaultRegistrationConfig = {
        type: RegistrationTypeEnum.Normal
    };

    const defaultEventAccessType = EventAccessTypeEnum.Authenticated;

    return (
        <MicrositeConfigProvider
            site={site}
            header={{}}
            registration={defaultRegistrationConfig}
            event={eventData?.events_by_pk || undefined}
            eventAccessType={defaultEventAccessType}>
            <Head>
                <title>{eventData?.events_by_pk?.title || 'Event Site'}</title>
                <meta name="robots" content="noindex"></meta>
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                <link rel="apple-touch-icon" sizes="57x57" href="/favicon/apple-icon-57x57.png" />
                <link rel="apple-touch-icon" sizes="60x60" href="/favicon/apple-icon-60x60.png" />
                <link rel="apple-touch-icon" sizes="72x72" href="/favicon/apple-icon-72x72.png" />
                <link rel="apple-touch-icon" sizes="76x76" href="/favicon/apple-icon-76x76.png" />
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="manifest" href="/site.webmanifest" />
            </Head>
            <ChakraProvider resetCSS theme={siteTheme}>
                <FirebaseAppProvider
                    firebaseConfig={site.firebase_project?.firebaseConfig || firebaseAppConfig}>
                    <AnalyticsProvider analyticsConfig={analyticsConfig}>
                        <IntlProvider
                            locale={router.locale || publicRuntimeConfig.defaultLocale}
                            defaultLocale={
                                router.defaultLocale || publicRuntimeConfig.defaultLocale
                            }
                            messages={localeMessages}>
                            <Component eventData={eventData} {...restPageProps} />
                        </IntlProvider>
                    </AnalyticsProvider>
                </FirebaseAppProvider>
            </ChakraProvider>
        </MicrositeConfigProvider>
    );
};

export default App;
