const firebaseAppConfig = JSON.parse(process.env.NEXT_PUBLIC_FIREBASE_CONFIG || '{}');

const analyticsConfig = {
    // Flag to show debugging information in console
    debug: false,
    // The collection in Firestore where data will be stored
    destination: process.env.NEXT_PUBLIC_ANALYTICS_COLLECTION || 'analytics_dev',
    // Flag to disable sending data
    disabled: false,
    // The interval of seconds to send watch time analytics
    watch_time_interval_seconds: 300
};

export { firebaseAppConfig, analyticsConfig };
