import { extendTheme, Theme, theme as chakraTheme, ThemeConfig } from '@chakra-ui/react';
import { Styles } from '@chakra-ui/theme-tools';
import merge from 'lodash.merge';

const config: ThemeConfig = {
    useSystemColorMode: false,
    initialColorMode: 'light'
};

const styles: Styles = {
    global: (props) => ({
        'html, body': {
            overflowX: 'hidden',
            fontSize: 'md',
            color: props.colorMode === 'dark' ? 'white' : 'black'
        },
        '#__next': {
            position: 'relative',
            overflow: 'auto',
            height: '100%'
        },
        '.next-image': { backgroundColor: 'white' },
        '.styled-scrollbar': {
            scrollbarWidth: 'thin',
            scrollbarColor: 'gray.400',
            '&::-webkit-scrollbar': {
                height: '1rem'
            },
            '&::-webkit-scrollbar-track': {
                color: 'gray.300',
                backgroundImage:
                    'repeating-linear-gradient(to right, currentColor, currentColor 1px, transparent 1px, transparent)',
                backgroundSize: '5px 5px'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'highlight',
                borderRadius: '20px',
                border: '3px solid',
                borderColor: 'white'
            }
        },
        '.livestream-message': {
            h1: {
                fontWeight: 'bold',
                fontSize: '2rem'
            },
            h2: {
                fontWeight: 'bold',
                fontSize: '1.4rem'
            },
            p: {
                marginBottom: '1rem'
            },
            'p:last-child': {
                marginBottom: 0
            },
            '.ql-align-center': {
                textAlign: 'center'
            },
            '.ql-align-right': {
                textAlign: 'right'
            },
            a: {
                borderBottom: '2px solid',
                fontWeight: 'bold',
                textDecoration: 'none'
            },
            'ol, ul': {
                marginLeft: '4'
            }
        },
        '.slate-img': {
            img: {
                maxHeight: 'none',
                width: '100%'
            }
        },
        '.slate-Toolbar': {
            padding: 0,
            margin: 0,
            borderBottom: 0
        }
    })
};

const defaultTheme = {
    colors: {
        brand: chakraTheme.colors.blue,
        highlight: chakraTheme.colors.blue[700],
        highlightText: chakraTheme.colors.white,
        livestream: chakraTheme.colors.red[600]
    },
    zIndices: {
        modal: 1200
    }
};

export type SiteTheme = Theme & { logo?: string | undefined };

const theme: SiteTheme = extendTheme({
    colors: {
        brand: chakraTheme.colors.blue,
        highlight: chakraTheme.colors.blue[700],
        highlightText: chakraTheme.colors.white,
        livestream: chakraTheme.colors.red[600]
    },
    logo: '/examples/logo.png',
    config,
    styles
});

export const getSiteTheme = (siteTheme: SiteTheme): SiteTheme => {
    const mergedTheme = merge(defaultTheme, siteTheme);
    return extendTheme({
        ...mergedTheme,
        config,
        styles
    });
};

export function hexToRGBA(hex: string, alpha = 1): string {
    let parseString = hex;
    if (hex.startsWith('#')) {
        parseString = hex.slice(1, 7);
    }
    if (parseString.length !== 6) {
        return hex;
    }
    const r = parseInt(parseString.slice(0, 2), 16);
    const g = parseInt(parseString.slice(2, 4), 16);
    const b = parseInt(parseString.slice(4, 6), 16);
    if (isNaN(r) || isNaN(g) || isNaN(b)) {
        return hex;
    }
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export default theme;
