declare global {
    interface Window {
        dataLayer: any;
    }
}

export const TAG_MANAGER_ID = process.env.NEXT_PUBLIC_TAG_MANAGER_ID;

interface PageEventProps {
    event: string;
    page: string;
}

export const sendPageViewEvent = (url: string) => {
    const event: PageEventProps = {
        event: 'pageview',
        page: url
    };

    window && window.dataLayer && window.dataLayer.push(event);
};

// https://developers.google.com/tag-manager/quickstart
export const TagManager = () => {
    if (!TAG_MANAGER_ID) {
        return null;
    }

    return (
        <script
            dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${TAG_MANAGER_ID}');`
            }}
        />
    );
};

export const TagManagerIframe = () => {
    if (!TAG_MANAGER_ID) {
        return null;
    }

    return (
        <noscript
            dangerouslySetInnerHTML={{
                __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${TAG_MANAGER_ID}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`
            }}
        />
    );
};
